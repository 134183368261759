<template>
  <h2 class="mb-8">
    <v-btn
      class="mr-3 backNav"
      fab
      dark
      x-small
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon dark>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    {{ pageTitle }}
  </h2>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: "Dompet Kilat",
    },
  },
};
</script>
