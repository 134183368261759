<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Detail Log Email BNI GFX'" />

    <div class="pt-2 px-4">
      <grid-mail-log-detail :dataMailLogs="logsDetail" />
    </div>
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import GridMailLogDetail from "../../components/Grid/GridMailLogDetail.vue";

export default {
  components: {
    HeaderContent,
    GridMailLogDetail,
  },

  data() {
    return {
      loading: false,
      logsDetail: {},
    };
  },

  async mounted() {
    this.fetchOneMailLog();
  },

  methods: {
    async fetchOneMailLog() {
      await store
        .dispatch("bniGfx/fetchOneMailLog", this.$route.params.id)
        .then((r) => {
          this.logsDetail = r[0];
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
  },
};
</script>
