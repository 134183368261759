<template>
  <v-row>
    <v-row id="mailLogDetail" class="my-2">
      <v-col cols="12">
        <h3>Detail Mail Log</h3>
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-medium text--secondary">
          Tanggal Pengiriman
        </div>
        <div v-if="logsData.created_at" class="pt-1">
          {{ convertDate(logsData.created_at) }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-medium text--secondary">
          NIK
        </div>
        <div v-if="logsData.nik" class="pt-1">
          {{ logsData.nik }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" md="4">
        <div class="font-weight-medium text--secondary">
          Email
        </div>
        <div v-if="logsData.email" class="pt-1">
          {{ logsData.email }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="font-weight-medium text--secondary">
          Username
        </div>
        <div v-if="logsData.username" class="pt-1">
          {{ logsData.username }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="font-weight-medium text--secondary">
          Password
        </div>
        <div v-if="logsData.password" class="pt-1">
          {{ logsData.password }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import converters from "../../helpers/converters.js";

export default {
  props: {
    dataMailLogs: Object,
  },

  computed: {
    logsData() {
      return this.dataMailLogs;
    },
  },

  methods: {
    convertDate(date) {
      return converters.date(date);
    },
  },
};
</script>
